// extracted by mini-css-extract-plugin
export var button = "V_v";
export var children = "V_cs";
export var disabled = "V_ck";
export var fullWidth = "V_cr";
export var iconWrapper = "V_cv";
export var large = "V_cn";
export var link = "V_bN";
export var loading = "V_cj";
export var loadingContainer = "V_ct";
export var medium = "V_cp";
export var primary = "V_ch";
export var secondary = "V_cl";
export var small = "V_cq";
export var tertiary = "V_cm";