// extracted by mini-css-extract-plugin
export var border1 = "cX_h2";
export var border2 = "cX_h3";
export var border3 = "cX_h4";
export var border4 = "cX_h5";
export var container = "cX_f";
export var dark = "cX_c4";
export var large = "cX_cn";
export var ldsRing = "cX_h6";
export var light = "cX_c3";
export var medium = "cX_cp";
export var primaryPressed = "cX_h7";
export var xlarge = "cX_h1";